import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { updateSMTPAccessDetails } from "../../../services/user";
import Popup from "../../../components/Popup";

const SMTPSettings = ({ isOpen, onClose, staffUser }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, getValues } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);
    setSuccess(null);
    setError(null);

    // Get the current value of newEmail from the form data
    const newimap_host = getValues("imap_host");
    const newimap_port = getValues("imap_port");
    const newsmtp_host = getValues("smtp_host");
    const newsmtp_port = getValues("smtp_port");
    const newSMTPPassword = getValues("staff_email_password");

    // Check if all the values are entered
    if (!newimap_host || !newimap_port || !newsmtp_host || !newsmtp_port || !newSMTPPassword) {
      // If it's empty or unchanged, set the error message and return
      setError({ message: "All Details are required." });
      setIsLoading(false);
      return;
    }

    // call server api here
    try {
      data = { ...data, staff_id: staffUser.id };
      const responseData = await updateSMTPAccessDetails(data);
      // console.log("responseData", responseData);
      setSuccess(responseData);
    } catch (error) {
      // console.log("errorData", error);

      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Popup isOpen={isOpen} onClose={onClose} title="Setup SMTP for Staff" mdWidth="max-w-md">
      <form className="space-y-2" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-xl pb-2">Incoming Email Details (IMAP)</h2>
        <div className="">
          <input id="imap_host" name="imap_host" type="text" autoComplete="imap_host" required defaultValue={staffUser?.imap_host} {...register("imap_host")} className="relative block w-full mb-2 px-3 py-2 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Host IMAP" />

          <input id="imap_port" name="imap_port" type="text" autoComplete="imap_port" required defaultValue={staffUser?.imap_port} {...register("imap_port")} className="relative block w-full mb-2 px-3 py-2 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Port IMAP" />
        </div>
        <h2 className="text-xl pb-2">Outgoing Email Details (SMTP)</h2>
        <div className="">
          <input id="smtp_host" name="smtp_host" type="text" autoComplete="smtp_host" required defaultValue={staffUser?.smtp_host} {...register("smtp_host")} className="relative block w-full mb-2 px-3 py-2 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="SMTP Host" />

          <input id="smtp_port" name="smtp_port" type="text" autoComplete="smtp_port" required defaultValue={staffUser?.smtp_port} {...register("smtp_port")} className="relative block w-full mb-2 px-3 py-2 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="SMTP Port" />
        </div>
        <h2 className="text-xl pb-2">Email App Password</h2>
        <div className="">
          <input id="staff_email_password" name="staff_email_password" type="text" autoComplete="staff_email_password" required defaultValue={staffUser?.staff_email_password} {...register("staff_email_password")} className="relative block w-full mb-2 px-3 py-2 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Auth Password" />
        </div>
        <div>
          <button type="submit" className={`group relative mt-4 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isLoading ? "bg-gray-500 cursor-not-allowed" : "bg-indigo-600"}`}>
            Update Email Details
          </button>
        </div>
      </form>

      <div className="pt-2 text-red-600">{error?.message}</div>
      <div className="pt-2 text-green-600">{success?.message}</div>
    </Popup>
  );
};

export default SMTPSettings;

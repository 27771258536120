import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { fetchMeUser, updateGHLAPIKey } from "../../../services/user";

const UpdateGHLKey = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, getValues } = useForm();
  const [currentUser, setCurrentUser] = useState(null);
  const fetchDataFromApi = async () => {
    try {
      const responseData = await fetchMeUser();
      // console.log("responseData", responseData);
      setCurrentUser(responseData?.data);
    } catch (error) {
      // setError(error);
    }
  };

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  const onSubmit = async (data) => {
    setIsLoading(true);
    setSuccess(null);
    setError(null);

    // Get the current value of newEmail from the form data
    const newghlAPIKey = getValues("ghlAPIKey");

    // Check if the newghlAPIKey is empty or unchanged
    if (!newghlAPIKey || newghlAPIKey === currentUser.ghlAPIKey) {
      // If it's empty or unchanged, set the error message and return
      setError({ message: "Please enter a new GHL API Key address." });
      setIsLoading(false);
      return;
    }

    // call server api here
    try {
      const responseData = await updateGHLAPIKey(data);
      // console.log("responseData", responseData);
      setSuccess(responseData);
      fetchDataFromApi();
    } catch (error) {
      // console.log("errorData", error);

      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="mb-4 bg-back-dark p-5 rounded-lg">
        <form className="space-y-2" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="text-xl pb-2">GHL API Key</h2>
          <div className="">
            <input id="ghlAPIKey" name="ghlAPIKey" type="text" autoComplete="ghlAPIKey" required defaultValue={currentUser?.ghlAPIKey} {...register("ghlAPIKey")} className="relative block w-full px-3 py-2 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="GHL API key" />

            <div>
              <button type="submit" className={`group relative mt-4 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isLoading ? "bg-gray-500 cursor-not-allowed" : "bg-indigo-600"}`}>
                Update GHL API key
              </button>
            </div>
          </div>
        </form>
        <div className="pt-2 text-red-600">{error?.message}</div>
        <div className="pt-2 text-green-600">{success?.message}</div>
      </div>

      <div className="mb-4 bg-back-dark p-5 rounded-lg">
        <h2 className="text-xl pb-2">GHL Authentication</h2>
        <a href={process.env.REACT_APP_BASE_URL + "/api/access_token/initiate"} target="_blank" rel="noopener" className="inline-block">
          <button type="button" className={`group relative mt-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isLoading ? "bg-gray-500 cursor-not-allowed" : "bg-indigo-600"}`}>
            {currentUser?.tokenExists ? "Re-Connect GHL Account" : "Connect GHL Account"}
          </button>
        </a>
        {currentUser?.tokenExists ? (
          <Link to="/dashboard/staff-users" className="inline-block ml-10">
            <button type="button" className={`group relative mt-10 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isLoading ? "bg-gray-500 cursor-not-allowed" : "bg-indigo-600"}`}>
              Configure Staff Users
            </button>
          </Link>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default UpdateGHLKey;

import { getAccessToken, logOut } from "./token";

export const fetchIntentPromptsAPI = async () => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/prompt_intent`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const createIntentPromptsAPI = async (postData) => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/prompt_intent`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();

    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const fetchAllIntentPromptsAPI = async () => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/prompt_intent/getAll`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const createNewIntentPromptsAPI = async (postData) => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/prompt_intent/create`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();

    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const fetchSingleIntentPromptsAPI = async (id) => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/prompt_intent/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const deletePromptAPI = async (id) => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/prompt_intent/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};

// Sidebar.js
import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { CircleGauge, CircleUser, Contact, LayoutDashboard, MessageSquareText, SquareUser, LogOut, ShieldQuestion, ShieldAlert, PhoneCall, MessageSquareCode } from "lucide-react";
import { useAuth } from "../hooks/AuthProvider";

function Sidebar() {
  const [selectedItem, setSelectedItem] = useState(1);
  const [selectedSubItem, setSelectedSubItem] = useState(0);
  const [selectedSubItemOpen, setSelectedSubItemOpen] = useState(0);
  const auth = useAuth();
  const currentPath = useLocation().pathname;
  console.log(currentPath);

  const handleItemClick = (ev, item) => {
    if (item.subItems?.length) {
      if (ev) ev.preventDefault();
      setSelectedSubItemOpen(selectedSubItemOpen === item.id ? 0 : item.id);
    } else {
      setSelectedItem(item);
      setSelectedSubItem(0);
    }
  };
  const handleSubItemClick = (item, subitem) => {
    setSelectedItem(item);
    setSelectedSubItem(subitem);
  };

  let items = [
    {
      id: 1,
      name: "Dashboard",
      link: "/dashboard",
      icon: <LayoutDashboard />,
    },
    {
      id: 2,
      name: "Campaigns",
      link: "campaigns",
      icon: <CircleGauge />,
    },
    {
      id: 9,
      name: "Call Logs",
      link: "calls",
      icon: <PhoneCall />,
    },
    {
      id: 3,
      name: "Assistants",
      link: "assistants",
      icon: <SquareUser />,
    },
    {
      id: 4,
      name: "Call Prompts",
      link: "prompts",
      icon: <MessageSquareText />,
    },
    {
      id: 5,
      name: "LLM Prompt",
      link: "intent-prompt/type-call",
      icon: <MessageSquareText />,
      subItems: [
        {
          id: 101,
          name: "Add New",
          link: "intent-prompt/create",
        },
        {
          id: 102,
          name: "Call",
          link: "intent-prompt/type-call",
        },
        {
          id: 103,
          name: "SMS",
          link: "intent-prompt/type-sms",
        },
        {
          id: 104,
          name: "Email",
          link: "intent-prompt/type-email",
        },
        {
          id: 105,
          name: "WhatsApp",
          link: "intent-prompt/type-whatsapp",
        },
        {
          id: 106,
          name: "Custom",
          link: "intent-prompt/type-custom",
        },
      ],
    },
    {
      id: 6,
      name: "FAQ",
      link: "faqs",
      icon: <ShieldQuestion />,
      subItems: [
        {
          id: 111,
          name: "FAQ List",
          link: "faqs",
        },
        {
          id: 112,
          name: "FAQ Prompt",
          link: "faq-prompt",
        },
      ],
    },
    {
      id: 7,
      name: "Objections",
      link: "objections",
      icon: <ShieldAlert />,
      subItems: [
        {
          id: 121,
          name: "Objections List",
          link: "objections",
        },
        {
          id: 122,
          name: "Objections Prompt",
          link: "objections-prompt",
        },
      ],
    },
    {
      id: 8,
      name: "Contacts",
      link: "contacts",
      icon: <Contact />,
    },
    {
      id: 10,
      name: "SMS Providers",
      link: "sms-providers",
      icon: <MessageSquareCode />,
    },
    {
      id: 11,
      name: "Account",
      link: "accounts",
      icon: <CircleUser />,
    },
  ];

  useEffect(() => {
    items.forEach((itm) => {
      if (currentPath === "/dashboard/" + itm.link && selectedItem !== itm) {
        setSelectedItem(itm);
      }

      if (itm.subItems?.length > 0) {
        itm.subItems.forEach((subItm) => {
          if (currentPath === "/dashboard/" + subItm.link && selectedSubItem !== subItm) {
            setSelectedItem(false, itm);
            setSelectedSubItem(subItm);
            setSelectedSubItemOpen(itm.id);
          }
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);

  return (
    <div>
      <div className="bg-slate-950 text-white h-full fixed top-0 left-0 bottom-0 overflow-y-auto w-sidebar">
        <div className="relative" style={{ paddingBottom: "90px", minHeight: "100vh" }}>
          <div className="noise-overlay"></div>
          <div className="self-center text-2xl font-semibold whitespace-nowrap text-center dark:text-white pt-5">AI Calls</div>
          <ul className="pl-5 pt-5">
            {items.map((item) => (
              <li key={item.id} className="py-1">
                <NavLink to={item.link} className={`block px-4 bg-gradient-to-r hover:from-blue-700 hover:to-blue-900 hover:rounded-l-md  ${selectedItem.id === item.id ? "from-blue-700 to-blue-900 rounded-l-md" : ""}`} onClick={(e) => handleItemClick(e, item)}>
                  <div className="flex flex-row gap-2 py-2">
                    {item.icon}
                    {item.name}
                  </div>
                </NavLink>

                {selectedSubItemOpen === item.id && item.subItems && (
                  <ul style={{ background: "#22252d", paddingLeft: "20px" }}>
                    {item.subItems.map((subItem) => (
                      <li key={subItem.id} className="py-1" style={{ borderBottom: "1px solid #33363d" }}>
                        <NavLink to={subItem.link} className={`block px-4 py-1 bg-gradient-to-r hover:from-blue-700 hover:to-blue-900 hover:rounded-l-md  ${selectedSubItem.id === subItem.id ? "from-blue-700 to-blue-900 rounded-l-md" : ""}`} onClick={() => handleSubItemClick(item, subItem)}>
                          {subItem.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
            <li className="py-1 absolute bottom-5 left-5 right-0">
              <div onClick={() => auth.logOut()} className={`block cursor-pointer px-4 bg-gradient-to-r hover:from-blue-700 hover:to-blue-900 hover:rounded-l-md`}>
                <div className="flex flex-row gap-2 py-2">
                  <LogOut />
                  Logout
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div className="bg-slate-950 fixed w-dashboard left-dashboard border-b border-gray-200 dark:border-gray-600 flex justify-start p-5">
          {selectedItem.icon}
          <span className="inline-block ml-2">{selectedItem.name}</span>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;

import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/Sidebar";

const DashboardLayout = () => {
  return (
    <div className="flex">
      <div className="flex-none relative z-10">
        <Sidebar />
      </div>
      <div className="w-dashboard ml-dashboard mt-top-dashboard">
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardLayout;

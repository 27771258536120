import React, { useState } from "react";
import Popup from "../../../../components/Popup";

import { createTestCallAPI } from "../../../../services/assistant";

const TestAssistant = ({ isOpen, onClose, formData }) => {
  // const vapi = new Vapi(process.env.REACT_APP_VAPI_PUBLICK_KEY);

  const usPhoneRegex = /^(\+1|1)?\s*\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

  // assistant states
  const [assistantError, setAssistantError] = useState(null);

  // call form phone states
  const [usingPhoneLoading, setUsingPhoneLoading] = useState(false);
  const [targetPhone, setTargetPhone] = useState("");
  const [phoneCallStarted, setPhoneCallStarted] = useState(false);

  const handleInputChange = (event) => {
    setTargetPhone(event.target.value);
  };

  const handleCallUsingPhoneClick = async () => {
    formData.target_phone = targetPhone;

    if (!formData.target_phone || !usPhoneRegex.test(formData.target_phone)) {
      setAssistantError({ message: "Provide a valid phone usa number." });
      return;
    }

    try {
      setUsingPhoneLoading(true);

      const responseData = await createTestCallAPI(formData);
      // console.log("responseData", responseData);
      if (responseData && responseData.status === "success") {
        setPhoneCallStarted(true);
      }
    } catch (error) {
      setAssistantError(error);
    } finally {
      setUsingPhoneLoading(false);
    }
  };

  const handleEndCallUsingPhoneClick = async () => {
    console.log("logic to end call here ");
    // Pending end call
    setPhoneCallStarted(false);
  };

  return (
    <Popup isOpen={isOpen} onClose={onClose} title="Test Assistant" mdWidth="max-w-md">
      <div>
        {/* Target phone field */}
        <div className="flex flex-col mt-5">
          <div className="py-2">
            <input id="target_phone" name="target_phone" type="text" value={targetPhone} onChange={handleInputChange} required className="relative block w-full px-3 py-3 border  bg-gray-950  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Target phone number" />
          </div>

          {phoneCallStarted ? (
            <button className={`flex text-white justify-center mt-5 bg-red-700 hover:bg-red-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5  py-2 `} type="button" onClick={handleEndCallUsingPhoneClick}>
              Call Started
            </button>
          ) : (
            <button disabled={usingPhoneLoading} className={`flex text-white justify-center mt-5 bg-blue-700 hover:bg-blue-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5  py-2  ${usingPhoneLoading ? "bg-blue-900 cursor-not-allowed" : ""} `} type="button" onClick={handleCallUsingPhoneClick}>
              {usingPhoneLoading ? "Calling..." : "Start call"}
            </button>
          )}
        </div>

        <div className="flex text-red-600 justify-center mt-4">{assistantError?.message}</div>
      </div>
    </Popup>
  );
};

export default TestAssistant;

import React, { useState, useEffect } from "react";
import { fetchCampaigns } from "../../../services/campaign";
import CreateCampaign from "./CreateCampaign";
import { BadgePlus } from "lucide-react";

const Campaigns = () => {
  const [campaigns, setCampaigns] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCreateOpen, setIsCreateOpen] = useState(false);

  const togglePopup = () => {
    setIsCreateOpen(!isCreateOpen);
  };

  const fetchDataFromApi = async () => {
    try {
      const responseData = await fetchCampaigns();
      setCampaigns(responseData.data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const showAlertPending = () => {
    alert("Pending");
  };

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const colorStatus = (campaign) => {
    switch (campaign.status) {
      case "not_started":
        return <div className="px-2 py-1 text-center inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-800 text-gray-400">Not Started</div>;
      case "active":
        return <div className="px-2 py-1 text-center inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Active</div>;
      case "Completed":
        return <div className="px-2 py-1 text-center inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-700 text-gray-200">Completed</div>;
      default:
        return <div></div>;
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center mt-10">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-10 w-10 opacity-90"></div>
        </div>
      ) : (
        <div>
          {campaigns && campaigns.length > 0 ? (
            <>
              <div className="w-full flex justify-end">
                <div className="py-5 flex gap-3 px-5">
                  <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5  py-2  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button" onClick={togglePopup}>
                    <BadgePlus className="inline mb-1 mr-1" size="16" />
                    New Campaign
                  </button>
                </div>
              </div>

              <div className="px-5">
                <table className="min-w-full divide-y bg-back-dark divide-color-lines rounded-lg">
                  <thead className="min-w-full rounded-md divide-color-lines">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Status
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Assistants
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Prompts
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Success Calls
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Total Calls
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Start
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-back-dark divide-y divide-color-lines">
                    {campaigns.map((campaign) => (
                      <tr key={campaign.id} className="hover:bg-gray-900 text-sm">
                        <td className="px-6 py-4 whitespace-wrap">{campaign.name}</td>
                        <td className="px-6 py-4 whitespace-wrap text-center">{colorStatus(campaign)}</td>
                        <td className="px-6 py-4 whitespace-wrap text-center">{campaign.active_assistants}</td>
                        <td className="px-6 py-4 whitespace-wrap text-center">{campaign.active_prompts}</td>
                        <td className="px-6 py-4 whitespace-wrap text-center">{campaign.success_calls}</td>
                        <td className="px-6 py-4 whitespace-wrap text-center">{campaign.total_calls}</td>
                        <td className="px-6 py-4 whitespace-wrap text-center">
                          <button onClick={showAlertPending} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5  py-2  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                            Start
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className="flex items-center justify-center text-center text-xl pt-10">
              <div>
                No Campaigns found.
                <div className="block text-base mt-5 mb-10">Start by creating new campaign.</div>
                <div className="flex items-center justify-center">
                  <div onClick={togglePopup} className="rounded-lg text-center overflow-hidden bg-blue-700 p-4 hover:bg-blue-900 cursor-pointer">
                    <BadgePlus className="inline mb-2" size="30" />
                    <p className="text-base">Create New Campaign</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <CreateCampaign isOpen={isCreateOpen} onClose={togglePopup} fetchDataFromApi={fetchDataFromApi} />
    </div>
  );
};

export default Campaigns;

import React, { useEffect, useState } from "react";
import { fetchStaffUsersAPI, saveStaffUsersAPI } from "../../../services/user";
import SMTPSettings from "./SMTPSettings";

const Ghlstaff = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [editStaff, setEditStaff] = useState(false);
  const [staffUsers, setStaffUsers] = useState(null);

  const handleCheckboxChange = (oneUser, oneIndex, checked) => {
    if (checked) {
      // Checked
      const newStaffUsers = staffUsers.map((itm, indx) => {
        const oneItm = itm;
        if (indx === oneIndex) {
          oneItm.staff_connect_email = 1;
        }

        return oneItm;
      });

      setStaffUsers(newStaffUsers);
      saveUserConnect(oneUser, 1);
    } else {
      // Not checked
      const newStaffUsers = staffUsers.map((itm, indx) => {
        const oneItm = itm;
        if (indx === oneIndex) {
          oneItm.staff_connect_email = 0;
        }

        return oneItm;
      });

      setStaffUsers(newStaffUsers);
      saveUserConnect(oneUser, 0);
    }
  };

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  const fetchDataFromApi = async () => {
    setIsLoading(true);

    try {
      const responseData = await fetchStaffUsersAPI();
      if (responseData && responseData.status === "success") {
        setStaffUsers(responseData.data);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveUserConnect = async (staffUser, doConnect) => {
    try {
      const responseData = await saveStaffUsersAPI({ staffUserId: staffUser.id, doConnect: doConnect });
      if (responseData && responseData.status === "success") {
        // Silent save
      }
    } catch (error) {
      setError(error);
    }
  };

  const handleOpenSMTP = (staffUser) => {
    if (openSettings) {
      setOpenSettings(false);
    } else {
      setOpenSettings(true);
      setEditStaff(staffUser);
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center mt-10">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-10 w-10 opacity-90"></div>
        </div>
      ) : (
        <div>
          {staffUsers && staffUsers.length > 0 ? (
            <>
              <div className="px-5">
                <table className="min-w-full divide-y bg-back-dark divide-color-lines rounded-lg">
                  <thead className="min-w-full rounded-md divide-color-lines">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-3 text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Email
                      </th>
                      <th scope="col" className="px-6 py-3 text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        SMTP Settings
                      </th>
                      <th scope="col" className="px-6 py-3 text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Connect Emails
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-back-dark divide-y divide-color-lines">
                    {staffUsers.map((staffUser, staffIndex) => (
                      <tr key={staffUser.id} className="hover:bg-gray-900 text-sm" style={staffUser?.imap_host && staffUser?.staff_connect_email ? { background: "#0c510c" } : {}}>
                        <td className="px-6 py-4 whitespace-wrap">{staffUser.staff_name}</td>
                        <td className="px-6 py-4 whitespace-wrap">{staffUser.staff_email}</td>
                        <td className="px-6 py-4 whitespace-wrap text-center">
                          <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5  py-2  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button" onClick={() => handleOpenSMTP(staffUser)}>
                            {staffUser?.imap_host ? "Update" : "Setup"}
                          </button>
                        </td>
                        <td className="px-6 py-4 whitespace-wrap text-center">
                          <input type="checkbox" checked={!!staffUser.staff_connect_email} onChange={(e) => handleCheckboxChange(staffUser, staffIndex, e.target.checked)} className="mr-2" />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className="flex items-center justify-center text-center text-xl pt-10">
              <div>No Staff Users found. Make sure GHL is Authenticated</div>
            </div>
          )}

          {error && <div className="pt-2 text-red-600">{error?.message}</div>}
        </div>
      )}

      {/* create  contact popup */}
      <SMTPSettings isOpen={openSettings} onClose={handleOpenSMTP} staffUser={editStaff} fetchDataFromApi={fetchDataFromApi} />
    </div>
  );
};

export default Ghlstaff;

import { getAccessToken, logOut } from "./token";

export const createAssistantAPI = async (postData) => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/assistants`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const fetchAssistants = async () => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/assistants`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const fetchSingleAssistantAPI = async (assistant_id) => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/assistants/${assistant_id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const fetchStaffUsers = async () => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/users/get_ghl_staff_users`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const assignStaffToAssistant = async (postData) => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/assistants/assign_staff_user`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const buyAssistantPhone = async (postData) => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/assistants/buy_phone`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const assignPromptToAssistant = async (postData) => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/assistants/assign_prompt`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const getTestAssistantWebCallAPI = async (postData) => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/assistants/get_test_assistant`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const createTestCallAPI = async (postData) => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/assistants/test_call`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const deleteAssistantAPI = async (id) => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/assistants/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const fetchVoices = async () => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/assistants/get_voices`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};
